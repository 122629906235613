<template>
  <div class="chose-large-wrap">
    <div class="logo-wrap">
      <img src="../../assets/llm-log.png" alt="" />
    </div>
    <div class="card-list-wrap">
      <div
        class="card-wrap"
        :class="item.model === 'deepseek-chat' ? 'recommend-wrap' : ''"
        v-for="(item, index) in modelList"
        :key="index"
      >
        <div v-show="item.model === 'deepseek-chat'" class="recommend-tag">
          {{ $t("certificate.Recommend") }}
        </div>
        <img :src="item.icon" alt="" />
        <div class="card-des">
          <div>{{ item.name }}</div>
          <div>
            <el-tooltip :content="item.description">
              <span>{{ item.description }}</span></el-tooltip
            >
          </div>
        </div>
      </div>
      <div class="card-wrap more-large">
        <img :src="moreai" alt="" />
        <div class="card-des more-ai">
          {{ $t("personal.Largelanguage.moreAi") }}
        </div>
      </div>
    </div>
    <div class="start-user-wrap" @click="showPhoneDialog">
      <span>
        {{ $t("personal.Largelanguage.Start") }}
      </span>
    </div>
    <ContactPhoneDialog
      :dialogVisible="dialogVisiblePhone"
      @cancelDialog="cancelDialogPhone"
    ></ContactPhoneDialog>
  </div>
</template>

<script>
import { getAllLLM } from "@/network/llm";
import moreai from "@/assets/img/moreai.png";
import ContactPhoneDialog from "@/components/contactPhoneDialog";
export default {
  components: { ContactPhoneDialog },
  data() {
    return {
      moreai,
      dialogVisiblePhone: false,
      modelList: [],
    };
  },
  mounted() {
    this.getAllLLM();
  },
  methods: {
    getAllLLM() {
      getAllLLM().then((res) => {
        this.modelList = res;
      });
    },
    cancelDialogPhone(value) {
      this.dialogVisiblePhone = false;
      if (value == "update") {
        this.$router.push({
          path: "/home",
        });
      }
    },
    showPhoneDialog() {
      // this.dialogVisiblePhone = true;
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chose-large-wrap {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/chosellm.png");
  background-size: cover;
  padding: 6%;
  text-align: left;
  .logo-wrap {
    img {
      width: 50%;
    }
  }
  .card-list-wrap {
    display: flex;
    justify-content: flex-start;
    width: 88%;
    flex-wrap: wrap;
    margin-top: 40px;
    position: fixed;
    top: 30%;
    .recommend-wrap {
      background: linear-gradient(
        135deg,
        rgba(227, 227, 255, 1) 0%,
        rgba(180, 156, 247, 1) 100%
      ) !important;
    }
    .card-wrap {
      margin-bottom: 40px;
      width: 28%;
      margin-right: 5%;
      height: 170px;
      border-radius: 20px;
      padding: 30px;
      //   background: linear-gradient(
      //     135deg,
      //     rgba(227, 227, 255, 1) 0%,
      //     rgba(180, 156, 247, 1) 100%
      //   );
      background: linear-gradient(
        135deg,
        rgba(227, 240, 255, 1) 0%,
        rgba(156, 173, 247, 1) 100%
      );
      box-shadow: 0px 40px 40px -20px rgba(0, 0, 0, 0.2);
      position: relative;
      .recommend-tag {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        height: 32px;
        line-height: 32px;
        width: 23%;
        text-align: center;
        text-indent: 1em;
        background-image: url("../..//assets/img/recommendtag.png"); /* 使用 background-image */
        background-size: cover;
        border-radius: 0 4px 0 0;
      }
      .card-des {
        float: left;
        margin-left: 12px;
        width: calc(100% - 142px);
        & > div:first-of-type {
          font-size: 24px;
          font-weight: 500;
        }
        & > div:last-of-type {
          font-size: 16px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 设置显示的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 设置显示的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      img {
        float: left;
        width: 110px;
        height: 110px;
      }
    }
    .more-large {
      display: flex;
      align-items: center;
      .more-ai {
        font-size: 24px;
        font-weight: 500;
        line-height: 34.75px;
        color: #003ca3;
      }
    }
  }
  .start-user-wrap {
    position: fixed;
    width: 88%;
    bottom: 5%;
    text-align: center;
    & > span {
      display: inline-block;
      width: 192px;
      height: 60px;
      border-radius: 8px;
      background: linear-gradient(
        90deg,
        rgba(0, 60, 163, 1) 0%,
        rgba(4, 126, 191, 1) 100%
      );
      box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.2);
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      line-height: 60px;
      cursor: pointer;
    }
  }
}
</style>
