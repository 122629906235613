import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
// 查询
export function getAllLLM() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/all`,
        method: "get",
    })
}
//禁用启用模型
export function enabledLLM(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/${params.modelConfigId}/enabled`,
        method: "put",
    })
}
// 设置默认模型
export function defaultedLLM(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/${params.modelConfigId}/defaulted`,
        method: "put",
    })
}
// 新增一个模型配置
export function addLLM(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config`,
        method: "post",
        data: params.data,
    })
}
// 编辑一个模型配置
export function editLLM(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/${params.modelConfigId}`,
        method: "put",
        data: params.data,
    })
}
// 查询当前有哪些模型提供商及其版本和相关能力
export function presetLLM() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/preset`,
        method: "get",
    })
}
// 删除一个模型配置
export function deleteLLM(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/model/config/${params.modelConfigId}`,
        method: "delete",
    })
}